<template>
  <div class="xet_order_detial">
    <div class="header">
      <svg
      @click='$router.go(-1)'
        class="returnPage"
        width="18"
        height="32"
        viewBox="0 0 18 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 2L2 16L16 30"
          stroke="#222222"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <div class="state">
        <svg
          width="40"
          height="36"
          viewBox="0 0 40 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="2"
            y="2"
            width="36"
            height="32"
            rx="4"
            stroke="black"
            stroke-width="3"
          />
          <path
            d="M15 2H25V20L20 17L15 20V2Z"
            stroke="black"
            stroke-width="3"
            stroke-linejoin="round"
          />
          <path d="M2.5 11.5H14.5" stroke="black" stroke-width="3" />
          <path d="M25 11.5H37" stroke="black" stroke-width="3" />
        </svg>

        <p>{{dGoods.order_state_type}}</p>
      </div>
    </div>

    <div class="xet_od_pos" v-if="dGoods.ship_address">
      <div class="info">
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="40" height="40" fill="white" />
          <path
            d="M35 17C35 25.2843 23 38 20 38C17 38 5 25.2843 5 17C5 8.71573 11.7157 2 20 2C28.2843 2 35 8.71573 35 17Z"
            stroke="#666666"
            stroke-width="3"
          />
          <circle
            cx="20.5"
            cy="16.5"
            r="4.5"
            stroke="#666666"
            stroke-width="3"
          />
        </svg>

        <div>{{ dGoods.ship_receiver }} {{ dGoods.ship_phone }}</div>
      </div> 
      <p>地址：{{ dGoods.ship_address }}</p>
    </div>

    <div class="xet_od_product">
      <div class="product_info">
        <img
          :src="dGoods.img_url"
        />
        <h2>{{ dGoods.purchase_name }}</h2>
        <div class="price">
          <span>¥{{ dGoods.price }}</span>
          <span>X{{ dGoods.count }}</span>
        </div>
      </div>

      <div class="btn">
        <div>联系客服</div>
        <div @click='serviceApply(dGoods.order_id)' v-if='dGoods.order_state >=1 && dGoods.order_stat <=4'>申请退款</div>
      </div>

      
      <div class="btn">
        <div>申请售后</div>
        <div @click='serviceApply(dGoods.order_id)' v-if='dGoods.order_state >=1 && dGoods.order_stat <=4'>申请退款</div>
      </div>
    </div>

    <div class="mailInfo">
      <div class="info">
        <div class="info_ul">
          <div class="info_li">
            <span>订单编号：</span>
            <p>{{ dGoods.order_id }}</p>
            <div class="btn">
              复制
            </div>
          </div>

          <div class="info_li">
            <span>下单时间：</span>
            <p>{{ dGoods.created_at }}</p>
          </div>
        </div>
        <div class="solid"></div>
      </div>

      <div class="datas">
        <div class="li" v-if='dGoods.total_price'>
          <span>商品总额：</span>
          <b>¥{{ dGoods.total_price }}</b>
          </div>
          <!-- <div class="li">
            <span>活动优惠：</span>
            <b>¥{{ dGoods.discount_arr[0].discount_price }}</b>
          </div> -->
         
          <div class="li" v-if='dGoods.discount_arr && dGoods.discount_arr.length !=0'>
            <span>优惠券：</span>
            <b>¥{{ dGoods.discount_arr[0].discount_price }}</b>
          </div>
          <div class="li">
            <span>运费：</span>
            <b>¥{{ dGoods.freight }}</b>
          </div>
          <div class="solid"></div>
        </div>

        <div class="total">
          <span>实付款：</span>
          <b>¥{{ dGoods.price }}</b>
        </div>
      </div>

      <div class="set" v-if='dGoods.order_state_type != "待发货" && dGoods.order_state_type != "已完成"'>
        <div @click='goXiaoet(dGoods.order_id)' v-if='dGoods.order_state >= 2 && dGoods.order_state <= 4'>查看物流</div>
        <!-- <div class="act">确认收货</div> --> 
        <div  @click='goXiaoet(dGoods.order_id)' v-if='dGoods.order_state == 0'>取消订单</div>
        <div class="act" @click='goXiaoet(dGoods.order_id)' v-if='dGoods.order_state == 0'>去支付</div>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from "@get/http";
export default {
  data() {
    return {
      dGoods: {},
    };
  },
  methods : {
    goXiaoet(id){
      window.location.href=`https://appg5q9tdm86610.h5.xiaoeknow.com/v1/usercenter/order/order_details?order_id=${id}`
    },
    async getData(){
      let { data } = await post(
          "/lw.Xiaoet/api",
          {
            data: {
              api_url: "https://api.xiaoe-tech.com/xe.order.detail/1.0.0",
              method: "POST",
              data: {
                // user_id: this.$store.state.user.xiaoeToken.user_id,
                order_id: this.$route.query.id,
              },
            },
          },
          {
            result: true,
            toast: false,
          }
        );
        if(data.order_info){
          this.dGoods = data.order_info;
          console.log(data.order_info);
        }
          
    },
  },
  async created() {
    this.getData();
  },
  watch : {
   '$route.query.id'(){
     if(this.$route.query.id){
      this.getData();
     }
   }
  }
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.xet_order_detial {
  margin-bottom: rem(100);
}
.header {
  display: flex;
  align-items: center;
  padding: rem(32) rem(30);
  box-sizing: border-box;

  .returnPage {
    width: rem(14);
    height: rem(28);
  }
  .state {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: rem(36);
      height: rem(32);
    }
    p {
      font-size: rem(34);
      text-align: center;
      color: #222222;
      margin-left: rem(12);
    }
  }
}

.xet_od_pos {
  width: rem(690);
  max-height: rem(167);
  border-radius: rem(16);
  margin: 0 auto rem(24);
  background: #fff;
  box-sizing: border-box;
  padding: rem(24);
  .info {
    display: flex;
    align-items: center;
    svg {
      width: rem(30);
      height: rem(36);
      margin-right: rem(16);
    }
    div {
      font-size: rem(26);
      color: #222222;
    }
  }
  p {
    line-height: rem(36);
    color: #989898;
    font-size: rem(26);
  }
}

.xet_od_product {
  width: rem(690);
  background: #ffffff;
  border-radius: rem(16);
  margin: 0 auto rem(24);
  padding: rem(24);
  box-sizing: border-box;
  .product_info {
    display: flex;
    box-sizing: border-box;
    padding: rem(24);
    img {
      width: rem(170);
      height: rem(170);
      border-radius: rem(8);
    }
    h2 {
      color: #222222;
      font-size: rem(28);
      width: rem(300);
      margin: 0 rem(16);
    }
    .price {
      span {
        display: block;
        font-size: rem(28);
        color: #989898;
        text-align: right;
      }
    }
  }
  .btn {
    display: flex;
    justify-content: flex-end;
    div {
      margin-right: rem(24);
      color: #666666;
      font-size: rem(24);
      text-align: center;
      width: rem(144);
      height: rem(56);
      border-radius: rem(56/2);
      line-height: rem(56);
      border: rem(2) solid #989898;
      box-sizing: border-box;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.mailInfo {
  width: rem(690);
  background: #ffffff;
  border-radius: rem(16);
  margin: 0 auto;
  padding: rem(24);
  box-sizing: border-box;
  .info {
    .info_li {
      display: flex;
      align-items: center;
      font-size: rem(24);
      margin-bottom: rem(16);
      span {
        color: #989898;
      }
      p {
        @include ell;
        color: #222222;
        flex: 1;
      }
      .btn {
        border: rem(2) solid #989898;
        box-sizing: border-box;
        border-radius: rem(34/2);
        width: rem(74);
        height: rem(34);
        line-height: rem(34);
        text-align: center;
      }
    }
  }
  .datas {
    .li {
      font-size: rem(24);
      display: flex;
      flex-direction: row;
      margin-bottom: rem(16);
      span {
        color: #989898;
      }
      b {
        flex: 1;
        text-align: right;
        color: #222222;
      }
    }
  }

  .total {
    font-size: rem(24);
    text-align: right;
    span {
      color: #989898;
    }
    b {
      color: #ff4848;
    }
  }
}

.solid {
  width: rem(642);
  height: 1px;
  margin: 0 auto rem(16);
  background: #ededed;
}

.set {
  height: rem(98);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  background: #fff;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  padding: 0 rem(30);
  div {
    width: rem(154);
    height: rem(56);
    line-height: rem(56);
    text-align: center;
    border: 1px solid #989898;
    box-sizing: border-box;
    border-radius: rem(28);
    font-size: rem(24);
    margin-left: rem(30);
  }

  .act {
    color: #ffffff;
    border: none;
    background: #ff4848;
  }
}
</style>
